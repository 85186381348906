import React from 'react';
import { MainLayout } from '../layouts';
import { SiteMetadata } from '../components/site-metadata';
import { ContactHero } from '../components/pages/contact/ContactHero';
import { SignUp } from '../components/pages/shared/SignUp';

export default () => (
  <MainLayout>
    <SiteMetadata pathname="/contact" title="Contact Us" />
    <ContactHero />
    <SignUp />
  </MainLayout>
);
