import React, { useState } from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { Box, Button, Field, Input, Typography } from '../../../components';
import websiteCopy from '../../../copy';

import heroImg from '../../../assets/unsplash/bruce-mars-FWVMhUa_wbY-unsplash-fit.jpg';
import { useForm } from '../../../hooks/useForm';
import { useMutation } from '@apollo/react-hooks';
import { SubmitContactFormMutation } from '../../../mutations/SubmitContactFormMutation';
import { Textarea } from '../../Input';

const StyledHero = styled(Box)(
  css({
    flexGrow: 1,
    paddingTop: ['146px', '146px', '210px'],
    paddingBottom: ['90px', '90px', '126px'],
    px: [5, 5, 5, 165, '370px'],
    background: `no-repeat url(${heroImg}) center center`,
    backgroundSize: 'cover'
  })
);

const validates = {
  fullName: formValues =>
    formValues.fullName.length > 2
      ? false
      : 'Name is invalid.',
  email: formValues =>
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      formValues.email
    )
      ? false
      : 'Please enter a valid email address.'
};
export const ContactHero = () => {
  const [submitted, setSubmitted] = useState(false);

  const { formValues, formErrors, onChanges, onReset, createOnSubmit } = useForm(
    {
      fullName: '',
      email: '',
      message: ''
    },
    validates
  );

  const [submitForm] = useMutation(SubmitContactFormMutation);

  const handleSubmit = createOnSubmit(variables => {
    submitForm({ variables })
      .then(data => {
        setSubmitted(true);
        onReset();
      })
      .catch(e => {
        console.log(e);
      });
  });

  return (
    <StyledHero>
      <Box width={['100%', '100%', '540px']}>
        <Typography variant="display" color="light" fontSize={['48px']}>
          { websiteCopy('CONTACT_PAGE_HEADER') }
        </Typography>
        <Typography color="light" mt={['6']}>
          { submitted ? websiteCopy('CONTACT_PAGE_THANKS') : websiteCopy('CONTACT_PAGE_DESCRIPTION') }
        </Typography>
      </Box>

      <Box
        as="form"
        mt={['48px']}
        width={['100%', '100%', '540px']}
        onSubmit={handleSubmit}
      >
        <Field>
          <Field.Label as="label" htmlFor="fullName">
            Full Name *
          </Field.Label>
          <Input
            value={formValues.fullName}
            onChange={onChanges.fullName}
            fluid
            type="text"
            id="fullName"
            name="fullName"
            placeholder="First Last"
          />
          {formErrors.fullName && <Field.Error>{formErrors.fullName}</Field.Error>}
        </Field>
        <Field>
          <Field.Label as="label" htmlFor="email">
            Email address *
          </Field.Label>
          <Input
            value={formValues.email}
            onChange={onChanges.email}
            error={formErrors.email}
            fluid
            type="text"
            id="email"
            name="email"
            placeholder="Your email"
          />
          {formErrors.email && <Field.Error>{formErrors.email}</Field.Error>}
        </Field>
        <Field>
          <Field.Label as="label" htmlFor="message">
            Message
          </Field.Label>
          <Textarea
            value={formValues.message}
            onChange={onChanges.message}
            fluid
            rows="5"
            cols="50"
            id="message"
            name="message"
            placeholder="Message"
          />
        </Field>
        <Box textAlign={['center', 'center', 'left']} mt={['48px']}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            width="180px"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </StyledHero>
  );
};
