import gql from 'graphql-tag';

export const SubmitContactFormMutation = gql`
  mutation SubmitContactFormMutation(
    $fullName: String!
    $email: Email!
    $message: String!
  ) {
    submitContactForm(
      input: { fullName: $fullName, email: $email, message: $message }
    ) {
      submited: boolean
    }
  }
`;
